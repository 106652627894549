// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/routes/api.proxy.ts"
);
import.meta.hot.lastModified = "1727886656296.2722";
}
// REMIX HMR END

import type { ActionArgs, LoaderArgs } from '@remix-run/node'

import { type RequestMethod } from '@/types'

import { log } from '~/logging/log.server'
import { getQueryParams } from '~/utils/network'
import { throwResponse } from '~/utils/throwResponse.server'

// This file is to allow us to have a more strict CSP and CORs policy while
// still being able to fetch cross-origin resources for matching requests

// TODO: store allowedRequestOptions in DB
type AllowedRequestOption =
  | {
      hostname: string
      urlPattern?: never
      allowedMethods: RequestMethod[]
    }
  | {
      hostname?: never
      urlPattern: RegExp
      allowedMethods: RequestMethod[]
    }

const allowedRequestOptions: AllowedRequestOption[] = [
  // for default letter avatars
  { hostname: 'ui-avatars.com', allowedMethods: ['GET'] },
  // For JLL's company logo
  { hostname: 'www.us.jll.com', allowedMethods: ['GET'] },
  // For Vouch's company logo
  { hostname: 'images.crunchbase.com', allowedMethods: ['GET'] },
  // CITI: company logo
  {
    hostname: 'pentagram-production.imgix.net',
    allowedMethods: ['GET'],
  },
]

export function getProxyUrl(url: string | URL | undefined | null) {
  const strUrl = typeof url === 'string' ? url : url?.toString()

  if (strUrl == null) return ''
  if (!shouldProxyUrl(strUrl)) return strUrl

  const encodedUrl = encodeURIComponent(strUrl)
  return `/api/proxy?url=${encodedUrl}`
}

function shouldProxyUrl(url?: string): boolean {
  if (url == null || typeof url !== 'string') return false
  try {
    const parsedUrl = new URL(url)
    return allowedRequestOptions.some((opt) => {
      if (typeof opt.hostname === 'string') {
        return opt.hostname === parsedUrl.hostname
      }
      return opt.urlPattern.test(parsedUrl.toString())
    })
  } catch {
    return false
  }
}

type ProxiedQueryParams = {
  url?: string
}

async function proxyRequest({ request }: ActionArgs | LoaderArgs) {
  const requestUrl = new URL(request.url)
  const { url } = getQueryParams<ProxiedQueryParams>(requestUrl)
  if (!url) return null
  const parsedUrl = new URL(url)

  // Only proceed if the request matches an entry within allowedRequestOptions
  if (
    !allowedRequestOptions.some((opt) => {
      const isMethodAllowed = opt.allowedMethods.includes(request.method)
      if (typeof opt.hostname === 'string') {
        return opt.hostname === parsedUrl.hostname && isMethodAllowed
      }
      return opt.urlPattern.test(parsedUrl.toString()) && isMethodAllowed
    })
  ) {
    return throwResponse(
      `Cannot make ${request.method} request to host: ${parsedUrl.hostname}`,
      400,
    )
  }

  log.trace(`proxying request to url: ${url}`)
  const response = await fetch(url)
  if (!response.ok) {
    return throwResponse(
      `Unable to fetch the resource. Status: ${response.status}`,
      response.status,
    )
  }

  return response
}

export async function loader(args: LoaderArgs) {
  return proxyRequest(args)
}

export async function action(args: ActionArgs) {
  return proxyRequest(args)
}
