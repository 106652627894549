// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Avatar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Avatar.tsx");
  import.meta.hot.lastModified = "1714169492156.195";
}
// REMIX HMR END

import * as AvatarPrimitive from '@radix-ui/react-avatar';
import * as React from 'react';
import { cn } from '~/utils/cn';
const AvatarMain = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <AvatarPrimitive.Root ref={ref} className={cn('relative flex shrink-0 overflow-hidden rounded', className)} {...props} />);
_c2 = AvatarMain;
AvatarMain.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = React.forwardRef(_c3 = ({
  className,
  ...props
}, ref) => {
  return <AvatarPrimitive.Image ref={ref} className={cn('aspect-square h-full w-full bg-white', className)} {...props} />;
});
_c4 = AvatarImage;
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = React.forwardRef(_c5 = ({
  className,
  ...props
}, ref) => <AvatarPrimitive.Fallback ref={ref} className={cn('flex h-full w-full items-center justify-center bg-gray-500 text-white', className)} {...props} />);
_c6 = AvatarFallback;
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
export const Avatar = Object.assign(AvatarMain, {
  Image: AvatarImage,
  Fallback: AvatarFallback
});
_c7 = Avatar;
var _c, _c2, _c3, _c4, _c5, _c6, _c7;
$RefreshReg$(_c, "AvatarMain$React.forwardRef");
$RefreshReg$(_c2, "AvatarMain");
$RefreshReg$(_c3, "AvatarImage$React.forwardRef");
$RefreshReg$(_c4, "AvatarImage");
$RefreshReg$(_c5, "AvatarFallback$React.forwardRef");
$RefreshReg$(_c6, "AvatarFallback");
$RefreshReg$(_c7, "Avatar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;